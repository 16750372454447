import React, {FunctionComponent} from "react"
import { Widget } from '@typeform/embed-react'


class PublicationEnquiryFormProps {
}

const PublicationEnquiryForm: FunctionComponent<PublicationEnquiryFormProps> = (props) => {
    return <Widget id="sDwyoy6L" style={{height:820}} className="my-form" />
}

export default  PublicationEnquiryForm
