import { Deal as IDeal }            from '@open-law/open-law-shared'
import React, { FunctionComponent } from 'react'

interface BillingSelectorFrequencyProps {
    deals: IDeal[]
    selectedBillingFrequency: string

    handleChangeBillingFrequency: (selectedBillingFrequency: string) => void
}

/**
 * Loops through all pricing options in the pricing scheme and provides a set of billing frequencies
 * @param {Deal[]} deals
 * @returns {Set<string>}
 */
function getUniqueBillingFrequencies(deals: IDeal[]): Set<string> | undefined {
    if (deals) {
        const frequencies: Set<string> = new Set()
        for (const deal of deals) {
            if (deal.pricingOptions) {
                for (const opt of deal.pricingOptions) {
                    if (opt.frequency) {
                        frequencies.add(opt.frequency)
                    }
                }
            }
        }
        return frequencies
    }
}

const BillingFrequencySelector: FunctionComponent<BillingSelectorFrequencyProps> = (props) => {

    const billingFrequencies: Set<string> | undefined = getUniqueBillingFrequencies(props.deals)

    return (
        <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            {
                billingFrequencies && Array.from(billingFrequencies)
                                       .map((frequency) => {
                                           return (
                                               <button key={frequency} type="button"
                                                       onClick={() => props.handleChangeBillingFrequency(frequency)}
                                                       className={`relative w-1/2 ${frequency === props.selectedBillingFrequency ? 'bg-white' : ''} border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8 capitalize`}>
                                                   {frequency} billing
                                               </button>
                                           )
                                       })
            }
        </div>
    )
}

export default BillingFrequencySelector
