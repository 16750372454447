import { Deal as IDeal }            from '@open-law/open-law-shared'
import React, { FunctionComponent } from 'react'
import {
    getBackgroundColour,
    getBorderStyle
}                                   from '../../utilities/project-colour-styles'
import FeatureList                  from './FeatureList'
import PricingOptions               from './PricingOptions'

interface DealProps {
    deal: IDeal
    selectedBillingFrequency: string
}

const Deal: FunctionComponent<DealProps> = (props) => {
    const {selectedBillingFrequency} = props
    const {
              pricingOptions,
              actionButton,
              dealCatchphrase,
              dealName,
              featureList,
              featureListTitle,
              dealColour = 'gray'
          }                          = props.deal

    return (
        <>
            <div className={'flex flex-col'}>
                <div
                    className={`bg-white flex flex-col border-t-8 border-b-0 ${getBorderStyle(dealColour, true)} shadow`}>
                    <div className={'flex justify-center items-center'} style={{flex: '1 0 100px'}}>
                        <h2 className="text-3xl font-medium text-gray-900 px-5 pt-5 text-center">
                            {dealName}
                        </h2>
                    </div>
                    <div style={{flex: '1 0 100px'}}>
                        <p className="mt-4 text-sm text-gray-500 px-5 text-center">
                            {dealCatchphrase}
                        </p>
                    </div>
                    <div style={{flex: '1 0 100px'}}>
                        {
                            pricingOptions ? <PricingOptions selectedBillingFrequency={selectedBillingFrequency}
                                                             pricingOptions={pricingOptions}/> : null
                        }
                    </div>
                    <div style={{flex: '1 0 100px'}} className={'mt-20 pb-8 px-6 flex justify-center items-center'}>
                        <a href={actionButton.url.external}
                           className={`block w-full ${getBackgroundColour(dealColour, true)} border ${getBorderStyle(dealColour, true)} rounded-md py-2 text-xl font-medium text-white text-center hover:bg-gray-900`}>
                            {actionButton.buttonText}
                        </a>
                    </div>
                </div>
                <div style={{flex: 4}} className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                        {featureListTitle}
                    </h3>
                    <FeatureList features={featureList}/>
                </div>
            </div>


        </>
    )
}
export default Deal
