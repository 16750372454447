import React, { FunctionComponent, useEffect, useState } from 'react'
import { Deal, DealGroup, PricingOption }                from '@open-law/open-law-shared'

interface ThreeDealsFeaturedDealProps {
    dealGroup: DealGroup
}

const ThreeDealsFeatureDeal: FunctionComponent<ThreeDealsFeaturedDealProps> = (props) => {

    const { dealGroup } = props
    const { deals, dealGroupDescription, dealGroupName } = dealGroup


    const [featuredDeal, setFeaturedDeal] = useState<Deal>()
    const [leftDeal, setLeftDeal] = useState<Deal>()
    const [rightDeal, setRightDeal] = useState<Deal>()


    useEffect(() => {

        let indexOfFeaturedDeal = -1
        const featuredDeal: Deal | null = deals.find((deal, idx) => {
            if (deal.featuredDeal) {
                indexOfFeaturedDeal = idx
                return deal
            }
        }) || null


        if (featuredDeal && indexOfFeaturedDeal !== -1) {
            const dealsWithoutFeatured = [...deals]
            dealsWithoutFeatured.splice(indexOfFeaturedDeal, 1)
            setLeftDeal(dealsWithoutFeatured[0])
            setRightDeal(dealsWithoutFeatured[1])
            setFeaturedDeal(featuredDeal)
        } else {
            setFeaturedDeal(deals[0])
            setLeftDeal(deals[1])
            setRightDeal(deals[2])
        }


    }, [])

    const renderPricing = (p: PricingOption) => (
        <>
            <div className="mt-4 flex items-center justify-center">
                <span
                    className="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                    {
                        !p.customPrice && <span
                            className="mt-2 mr-2 text-4xl font-medium">
                            $
                        </span>
                    }
                    <span className="font-extrabold">
                        {p.customPrice ? p.customPrice : p.cost}
                    </span>
                </span>
                {
                    !p.customPrice && (
                        <span
                            className="text-xl font-medium text-gray-500">
                            /{p.frequency}
                        </span>
                    )
                }
            </div>
            {
                p.billingNote && (
                    <div className={'flex items-center justify-center mt-4'}>
                        <span className={`text-xs text-gray-400`}>
                            {p.billingNote}
                        </span>
                    </div>
                )
            }
        </>
    )


    const renderFeatureList = (features: string[]) => (
        <ul className="space-y-4">
            {
                features.map((f) => <li className="flex items-start">
                        <div className="flex-shrink-0">
                            <svg className="flex-shrink-0 h-6 w-6 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor"
                                 aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p className="ml-3 text-base font-medium text-gray-500">
                            {f}
                        </p>
                    </li>
                )
            }
        </ul>
    )

    return (
        <div className="bg-green-900">
            <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
                <div className="text-center">
                    <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                        {dealGroupName}
                    </h2>
                    <p className="mt-2 text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                        {dealGroupDescription}
                    </p>
                    <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
                        {dealGroupDescription}
                    </p>
                </div>
            </div>
            <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
                <div className="relative z-0">
                    <div className="absolute inset-0 h-5/6 bg-green-900 lg:h-2/3"></div>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="relative lg:grid lg:grid-cols-7">
                            {
                                leftDeal && (
                                    <div
                                        className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                                        <div
                                            className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                                            <div className="flex-1 flex flex-col">
                                                <div className="bg-white px-6 py-10">
                                                    <div>
                                                        <h3 className="text-center text-2xl font-medium text-gray-900">
                                                            {leftDeal.dealName}
                                                        </h3>
                                                        {leftDeal.pricingOptions && renderPricing(leftDeal.pricingOptions[0])}
                                                    </div>
                                                </div>
                                                <div
                                                    className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                                                    {renderFeatureList(leftDeal.featureList)}
                                                    <div className="mt-8">
                                                        <div className="rounded-lg shadow-md">
                                                            <a href="#"
                                                               className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-green-600 hover:bg-gray-50"
                                                               aria-describedby="tier-hobby">
                                                                {leftDeal.actionButton.buttonText}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            }
                            {
                                featuredDeal && (
                                    <div
                                        className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                                        <div className="relative z-10 rounded-lg shadow-xl">
                                            <div
                                                className="pointer-events-none absolute inset-0 rounded-lg border-2 border-green-600"
                                                aria-hidden="true"></div>
                                            <div className="absolute inset-x-0 top-0 transform translate-y-px">
                                                <div className="flex justify-center transform -translate-y-1/2">
                                                    <span
                                                        className="inline-flex rounded-full bg-green-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                                                        Most popular
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                                                <div>
                                                    <h3 className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6"
                                                        id="tier-growth">
                                                        {featuredDeal.dealName}
                                                    </h3>
                                                    {
                                                        featuredDeal.pricingOptions && renderPricing(featuredDeal.pricingOptions[0])
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                                                {renderFeatureList(featuredDeal.featureList)}
                                                <div className="mt-10">
                                                    <div className="rounded-lg shadow-md">
                                                        <a href={featuredDeal.actionButton.url}
                                                           className="block w-full text-center rounded-lg border border-transparent bg-green-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-green-700"
                                                           aria-describedby="tier-growth">
                                                            {featuredDeal.actionButton.buttonText}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                rightDeal && (
                                    <div
                                        className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                                        <div
                                            className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                                            <div className="flex-1 flex flex-col">
                                                <div className="bg-white px-6 py-10">
                                                    <div>
                                                        <h3 className="text-center text-2xl font-medium text-gray-900"
                                                            id="tier-scale">
                                                            {rightDeal.dealName}
                                                        </h3>
                                                        {rightDeal.pricingOptions && renderPricing(rightDeal.pricingOptions[0])}
                                                    </div>
                                                </div>
                                                <div
                                                    className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                                                    {renderFeatureList(rightDeal.featureList)}
                                                    <div className="mt-8">
                                                        <div className="rounded-lg shadow-md">
                                                            <a href="#"
                                                               className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-green-600 hover:bg-gray-50"
                                                               aria-describedby="tier-scale">
                                                                {rightDeal.actionButton.buttonText}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ThreeDealsFeatureDeal
