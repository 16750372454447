import React, { FunctionComponent } from 'react'
import { Deal }                     from '@open-law/open-law-shared'

interface SingleDealWithFeaturesProps {
    deal: Deal

}

const SingleDealWithFeatures: FunctionComponent<SingleDealWithFeaturesProps> = (props) => {

    const {
        actionButton,
        dealCatchphrase,
        dealColour,
        dealName,
        featureList,
        featureListTitle,
        pricingOptions
    } = props.deal

    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="pb-16 xl:flex xl:items-center xl:justify-between">
                    <div>
                        <h1 className="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
                            <span className="text-gray-900">JADE Professional - {dealName} Subscription</span>
                            <br />
                            <span className="text-green-600">${pricingOptions[0].cost} per month</span>
                            <span className={'text-gray-400 font-light text-xs'}> {pricingOptions[0].billingNote}</span>
                        </h1>
                        <p className="mt-5 text-xl text-gray-500">
                            {dealCatchphrase}
                        </p>
                    </div>
                    <a href="#"
                       className="mt-8 w-full bg-green-600 border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-green-700 sm:mt-10 sm:w-auto xl:mt-0">
                        {actionButton.buttonText}
                    </a>
                </div>
                <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
                    <div>
                        <h2 className="text-base font-semibold text-green-600 tracking-wide uppercase">
                            By lawyers, for lawyers.
                        </h2>
                        <p className="mt-2 text-3xl font-extrabold text-gray-900">
                            All-in-one platform
                        </p>
                        <p className="mt-4 text-lg text-gray-500">
                            Research tools for legal professionals. Get the answers you need using a firm foundation.
                            Fall in love with legal research which is effective, efficient, and enjoyable.


                        </p>
                    </div>
                    <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
                        <ul className="divide-y divide-gray-200">
                            {
                                featureList.map((feature, idx) => idx <= 5 && (
                                    <li className="py-4 flex">
                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500"
                                             xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-3 text-base text-gray-500">
                                            {feature}
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                        <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
                            {
                                featureList.map((feature, idx) => idx > 5 && (
                                    <li className="py-4 flex">
                                        <svg className="flex-shrink-0 h-6 w-6 text-green-500"
                                             xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-3 text-base text-gray-500">
                                            {feature}
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleDealWithFeatures
