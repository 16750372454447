import {CallToActionWithVideoData} from '@open-law/open-law-shared'
import React, {FunctionComponent}  from 'react'

interface CtaVideoProps {
    data: CallToActionWithVideoData
}


export const CtaVideo: FunctionComponent<CtaVideoProps> = (props) => {

    const cta: CallToActionWithVideoData = props.data

    /*

    # Importing a TailwindUI Component into React

    - rename class attributes to className
    - review and remove all html comments
    - ensure all xml elements are closed
    - check red squiggly lines for any errors and correct them i.e stroke-width attribute
     */


    return (
        <section>
            <div className="relative bg-gray-800">
                <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                    <img
                        className="w-full h-full object-cover"
                        src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
                        alt=""/>
                </div>
                <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                    <div className="md:ml-auto md:w-1/2 md:pl-10">
                        <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                            {cta.leadText}
                        </h2>
                        <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                            We’re here to help
                        </p>
                        <p className="mt-3 text-lg text-gray-300">
                            <em className={'drop-cap'}>L</em>orem ipsum dolor sit amet, consectetur adipiscing elit.
                            Et, egestas tempus tellus etiam
                            sed. Quam a scelerisque amet ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat
                            quisque ut interdum tincidunt duis.
                        </p>
                        <div className="mt-8">
                            <div className="inline-flex rounded-md shadow">
                                <a href="#"
                                   className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                                    Visit the help center
                                    <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path
                                            d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"/>
                                        <path
                                            d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default CtaVideo
