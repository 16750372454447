import React, {FunctionComponent, useEffect, useState} from 'react'
import {PricingOption as IPricingOption} from '@open-law/open-law-shared'
import useAnimateNumber from 'use-animate-number'

interface PricingOptionsProps {
    pricingOptions: IPricingOption[]
    selectedBillingFrequency?: string
}

const PricingOptions: FunctionComponent<PricingOptionsProps> = (props) => {

    const animateNumberOptions = {
        duration: 2000,
        enterance: true,
        direct: false,
        disabled: false,
        decimals: 0
    }

    const [costValue, setCostValue] = useAnimateNumber(0, animateNumberOptions)
    const [customPrice, setCustomPrice] = useState('')
    const [pricingOption, setPricingOption] = useState<IPricingOption>(props.pricingOptions[0])


    const calculateCostDisplay = (cost: number, frequency: 'yearly' | 'monthly' | 'weekly' | 'daily', costDisplay: 'yearly' | 'monthly' | 'weekly' | 'daily') => {


        const frequencyValues = {
            monthly: 12,
            weekly: 52,
            daily: 365
        }
        if (costDisplay !== frequency) {
            switch (costDisplay) {
                case 'monthly': {
                    return Math.round(cost / frequencyValues.monthly)
                }
                case 'weekly': {
                    return Math.round(cost / frequencyValues.weekly)
                }
                case 'daily': {
                    return Math.round(cost / frequencyValues.daily)
                }
                default: {
                    return cost
                }
            }
        }
        return cost
    }


    useEffect(() => {
        if (props.pricingOptions) {
            for (const opt of props.pricingOptions) {
                if (!opt.cost && opt.customPrice) {
                    setPricingOption(opt)
                    setCustomPrice(opt.customPrice)
                }
            }
        }
        if (!pricingOption) {
            setPricingOption(props.pricingOptions[0])
        }
    }, [])

    useEffect(() => {
        if (props.pricingOptions) {
            for (const opt of props.pricingOptions) {
                if (opt.frequency === props.selectedBillingFrequency) {
                    setPricingOption(opt)
                    if (opt && opt.cost) {
                        if (opt.costDisplay) {
                            setCostValue(calculateCostDisplay(opt.cost, opt.frequency, opt.costDisplay), false)
                        } else {
                            setCostValue(opt.cost, false)
                        }
                    } else if (!opt.cost && opt.customPrice) {
                        setCustomPrice(opt.customPrice)
                    }
                }
            }
        } else {
            setPricingOption(props.pricingOptions[0])
        }
    }, [props.selectedBillingFrequency])

    return (
        <>
            {
                pricingOption && (
                    <>
                        {
                            customPrice ? (
                                <p className="mt-8 text-center" style={{height:70}}>
                                    <span className="text-4xl font-extrabold text-gray-900 ">
                                        {pricingOption.customPrice}
                                    </span>
                                </p>
                            ) : (
                                <div style={{display: "flex", flexDirection: "column", height:70}}>
                                    <div style={{flex:2}}>
                                        <p className="mt-8 text-center">
                                            <span className="text-4xl font-extrabold text-gray-900">
                                                ${costValue}
                                            </span>
                                                    <span className="text-base font-medium text-gray-500">
                                                {` ${pricingOption.currency} /${pricingOption.costDisplay ? pricingOption.costDisplay : pricingOption.frequency}`}
                                            </span>
                                        </p>
                                    </div>
                                    <div style={{flex:0.5}}>
                                        <p className="text-center px-5">
                                            <span className={`text-xs text-gray-400`}>
                                            {pricingOption.billingNote}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default PricingOptions
