import React, { FunctionComponent } from 'react'
import { Author, Project }          from '../../templates/projects'

interface ProjectListProps {
    projects: Project[]
    heading: string,
    blurb: string
}

const ProjectList: FunctionComponent<ProjectListProps> = (props) => {

    const { projects, heading, blurb } = props
    const renderProjectList = (projects: Project[]) => {
        return (
            <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                <div className="absolute inset-0">
                    <div className="bg-white h-1/3 sm:h-2/3"></div>
                </div>
                <div className="relative max-w-7xl mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                            {heading}
                        </h2>
                        {
                            blurb && blurb.split('\n').map((line, idx) => (
                                <p key={idx} className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                    {line}
                                </p>
                            ))
                        }
                    </div>
                    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {
                            projects && projects.map((project) => !project.disabled ? renderProjectItem(project) : null)
                        }
                    </div>
                </div>
            </div>
        )
    }

    const buttonStyles = `text-base font-semibold text-blue-600 hover:text-blue-500 mt-3`

    const renderProjectItem = (project: Project) => <div key={project.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
            <a href={`/projects/${project && project.slug && project.slug.current ? project.slug.current : ''}`} className="block mt-2">
                <img className="h-48 w-full object-cover rounded-t-lg"
                 src={project.projectImage ? project.projectImage.asset.url : 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80'}
                 alt={project.projectImage ? project.projectImage.alt : ''} />
            </a>
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1 flex justify-end">
                <a href={`/projects/${project && project.slug && project.slug.current ? project.slug.current : ''}`} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                        {project.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                        {project.projectTagline}
                    </p>
                </a>
            </div>
            {project.projectLead && renderProjectLead(project.projectLead)}
        </div>
    </div>

    const renderProjectLead = (projectLead: Author) => (
        <>
            <div className="mt-3 flex">
                <p className="text-sm font-medium text-gray-500">
                    Project Lead
                </p>
            </div>
            <div className="mt-2 flex items-center">
                <div className="flex-shrink-0">
                    <a href="#">
                        <span className="sr-only">{projectLead.name}</span>

                        <img className="h-10 w-10 rounded-full"
                             src={projectLead.image.asset.url}
                             alt="" />
                    </a>
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                        <a href="#" className="hover:underline">
                            {projectLead.name}
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
    return projects && projects.length > 0 ? renderProjectList(projects) : <></>
}

export default ProjectList

