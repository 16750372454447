import React, { FunctionComponent, useEffect, useState } from 'react'
import OneTo100Logo                                      from '../../assets/logos/one-to-100.png'

interface Book {
    'ID': number
    'Name': string
    'Owner': string | null
    'Number': number
    'Patron': string | null
    'ClassName': string
    'Created': string
    'LastEdited': string
    'Reporters': string
    'Requested': string | null
    'Scanned': 0 | 1


}

const ClrsInfograph: FunctionComponent<{}> = (props) => {

    const rawData: Book[] = [
        {
            'ID': 29,
            'Name': 'CLR Volume 4 (Parts 1 and 2)',
            'Owner': null,
            'Number': 4,
            'Patron': 'BarNet',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-05 15:24:04',
            'Reporters': 'Langton, Bennet; Pilcher, N. G.; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 27,
            'Name': 'CLR Volume 2',
            'Owner': null,
            'Number': 2,
            'Patron': 'Gregory Burton SC',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-30 12:36:52',
            'Reporters': 'Langton, Bennet; Manning, H. E.; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 28,
            'Name': 'CLR Volume 3',
            'Owner': null,
            'Number': 3,
            'Patron': 'Richard Howard Weinstein',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-24 14:51:57',
            'Reporters': 'Langton, Bennet; Manning, H. E.; Pilcher, N. G.; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 25,
            'Name': 'CLR Volume 1',
            'Owner': null,
            'Number': 1,
            'Patron': 'BarNet',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-24 14:39:22',
            'Reporters': 'Barton, W. A.; Langton, Bennet; Manning, H. E.; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 30,
            'Name': 'CLR Volume 5',
            'Owner': null,
            'Number': 5,
            'Patron': 'David Talintyre',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-07-07 10:12:08',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 31,
            'Name': 'CLR Volume 6',
            'Owner': null,
            'Number': 6,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 32,
            'Name': 'CLR Volume 7',
            'Owner': null,
            'Number': 7,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 33,
            'Name': 'CLR Volume 8',
            'Owner': null,
            'Number': 8,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 34,
            'Name': 'CLR Volume 9',
            'Owner': null,
            'Number': 9,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; Weigall, C. E.; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 35,
            'Name': 'CLR Volume 10',
            'Owner': null,
            'Number': 10,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; Weigall, C. E.; White, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 36,
            'Name': 'CLR Volume 11',
            'Owner': null,
            'Number': 11,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:37:38',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; Weigall, C. E.; White, C. A.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 37,
            'Name': 'CLR Volume 12',
            'Owner': null,
            'Number': 12,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:37:50',
            'Reporters': 'Jacques, H. V.; Langton, Bennet; Weigall, C. E.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 38,
            'Name': 'CLR Volume 13',
            'Owner': null,
            'Number': 13,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:37:58',
            'Reporters': 'Langton, Bennet',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 39,
            'Name': 'CLR Volume 14',
            'Owner': null,
            'Number': 14,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:38:06',
            'Reporters': 'Langton, Bennet',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 40,
            'Name': 'CLR Volume 15',
            'Owner': null,
            'Number': 15,
            'Patron': 'Gilbert + Tobin',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-27 14:37:58',
            'Reporters': 'Langton, Bennet',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 41,
            'Name': 'CLR Volume 16',
            'Owner': null,
            'Number': 16,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:14:51',
            'Reporters': 'Langton, Bennet; McGhie, Norman',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 42,
            'Name': 'CLR Volume 17',
            'Owner': null,
            'Number': 17,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:14:43',
            'Reporters': 'Gavan Duffy, D.; Langton, Bennet; McGhie, Norman',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 43,
            'Name': 'CLR Volume 18',
            'Owner': null,
            'Number': 18,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:38:14',
            'Reporters': 'Campbell, A.L.; Gavan Duffy, D.; Langton, Bennet; McGhie, Norman',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 44,
            'Name': 'CLR Volume 19',
            'Owner': null,
            'Number': 19,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:38:22',
            'Reporters': 'Campbell, A.L.; Gavan Duffy, D.; Langton, Bennet; McGhie, Norman',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 45,
            'Name': 'CLR Volume 20',
            'Owner': null,
            'Number': 20,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:38:30',
            'Reporters': 'Gavan Duffy, D.; Gore, R T.; Langton, Bennet',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 46,
            'Name': 'CLR Volume 21',
            'Owner': null,
            'Number': 21,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:38:42',
            'Reporters': 'Gore, R T.; Langton, Bennet; Weston, C. A.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 47,
            'Name': 'CLR Volume 22',
            'Owner': null,
            'Number': 22,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:14:33',
            'Reporters': 'Gore, R T.; Langton, Bennet; Weston, C. A.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 48,
            'Name': 'CLR Volume 23',
            'Owner': null,
            'Number': 23,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:14:24',
            'Reporters': 'Langton, Bennet; Weston, C. A.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 49,
            'Name': 'CLR Volume 24',
            'Owner': null,
            'Number': 24,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:14:18',
            'Reporters': 'Langton, Bennet; Weston, C. A.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 50,
            'Name': 'CLR Volume 25',
            'Owner': null,
            'Number': 25,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:14:10',
            'Reporters': 'Langton, Bennet; McTague, Neil; Weston, C. A.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 51,
            'Name': 'CLR Volume 26',
            'Owner': null,
            'Number': 26,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Langton, Bennet; McTague, Neil; Weston, C. A.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 52,
            'Name': 'CLR Volume 27',
            'Owner': null,
            'Number': 27,
            'Patron': 'Edmund Barton Chambers, Adelaide',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:06:44',
            'Reporters': 'Langton, Bennet; McTague, Neil',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 53,
            'Name': 'CLR Volume 28',
            'Owner': null,
            'Number': 28,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Langton, Bennet; McTague, Neil',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 54,
            'Name': 'CLR Volume 29',
            'Owner': null,
            'Number': 29,
            'Patron': 'Fuji Xerox Australia Pty Ltd',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:07:45',
            'Reporters': 'Langton, Bennet; McTague, Neil',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 55,
            'Name': 'CLR Volume 30',
            'Owner': null,
            'Number': 30,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:38:49',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 56,
            'Name': 'CLR Volume 31',
            'Owner': null,
            'Number': 31,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:38:58',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 57,
            'Name': 'CLR Volume 32',
            'Owner': null,
            'Number': 32,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:39:06',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 58,
            'Name': 'CLR Volume 33',
            'Owner': null,
            'Number': 33,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:39:12',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 59,
            'Name': 'CLR Volume 34',
            'Owner': null,
            'Number': 34,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:39:20',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 60,
            'Name': 'CLR Volume 35',
            'Owner': null,
            'Number': 35,
            'Patron': 'Michael Green',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-30 15:18:59',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 61,
            'Name': 'CLR Volume 36',
            'Owner': null,
            'Number': 36,
            'Patron': 'Francis Burt Chambers',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:09:07',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 62,
            'Name': 'CLR Volume 37',
            'Owner': null,
            'Number': 37,
            'Patron': 'Francis Burt Chambers',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:09:20',
            'Reporters': 'Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 63,
            'Name': 'CLR Volume 38',
            'Owner': null,
            'Number': 38,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:14:00',
            'Reporters': 'Feltham, P. V.; Langton, Bennet; McTague, Neil; Wassell, J. L',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 64,
            'Name': 'CLR Volume 39',
            'Owner': null,
            'Number': 39,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:39:27',
            'Reporters': 'Feltham, P. V.; Healy, E. F.; Jeffriess, B. J.; Langton, Bennet; Reed, G. S.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 65,
            'Name': 'CLR Volume 40',
            'Owner': null,
            'Number': 40,
            'Patron': 'Minter Ellison',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Healy, E. F.; Jeffriess, B. J.; Langton, Bennet; Reed, G. S.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 66,
            'Name': 'CLR Volume 41',
            'Owner': null,
            'Number': 41,
            'Patron': 'Minter Ellison',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Langton, Bennet; Wiseman, H. Dallas',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 67,
            'Name': 'CLR Volume 42',
            'Owner': null,
            'Number': 42,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:39:35',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wiseman, H. Dallas',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 68,
            'Name': 'CLR Volume 43',
            'Owner': null,
            'Number': 43,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:39:56',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 69,
            'Name': 'CLR Volume 44',
            'Owner': null,
            'Number': 44,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:40:05',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 70,
            'Name': 'CLR Volume 45',
            'Owner': null,
            'Number': 45,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:40:12',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 71,
            'Name': 'CLR Volume 46',
            'Owner': null,
            'Number': 46,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:13:48',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 72,
            'Name': 'CLR Volume 47',
            'Owner': null,
            'Number': 47,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:40:19',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 73,
            'Name': 'CLR Volume 48',
            'Owner': null,
            'Number': 48,
            'Patron': 'Patricia Lane',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-30 09:49:52',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 74,
            'Name': 'CLR Volume 49',
            'Owner': null,
            'Number': 49,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:13:34',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 75,
            'Name': 'CLR Volume 50',
            'Owner': null,
            'Number': 50,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:13:27',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 76,
            'Name': 'CLR Volume 51',
            'Owner': null,
            'Number': 51,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 77,
            'Name': 'CLR Volume 52',
            'Owner': null,
            'Number': 52,
            'Patron': 'Fuji Xerox Australia',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 78,
            'Name': 'CLR Volume 53',
            'Owner': null,
            'Number': 53,
            'Patron': 'Sarah McNaughton',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-28 13:05:46',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 79,
            'Name': 'CLR Volume 54',
            'Owner': null,
            'Number': 54,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:40:28',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 80,
            'Name': 'CLR Volume 55',
            'Owner': null,
            'Number': 55,
            'Patron': 'John Maconachie QC',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:09:39',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 81,
            'Name': 'CLR Volume 56',
            'Owner': null,
            'Number': 56,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:13:20',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 82,
            'Name': 'CLR Volume 57',
            'Owner': null,
            'Number': 57,
            'Patron': 'Dever’s List',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:09:51',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 83,
            'Name': 'CLR Volume 58',
            'Owner': null,
            'Number': 58,
            'Patron': 'Dever’s List',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:09:59',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 84,
            'Name': 'CLR Volume 59',
            'Owner': null,
            'Number': 59,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:13:13',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 85,
            'Name': 'CLR Volume 60',
            'Owner': null,
            'Number': 60,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:40:38',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 86,
            'Name': 'CLR Volume 61',
            'Owner': null,
            'Number': 61,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:40:50',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 87,
            'Name': 'CLR Volume 62',
            'Owner': null,
            'Number': 62,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-10 09:41:00',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Gillard, Oliver J.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 88,
            'Name': 'CLR Volume 63',
            'Owner': null,
            'Number': 63,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:13:06',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Gillard, Oliver J.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 89,
            'Name': 'CLR Volume 64',
            'Owner': null,
            'Number': 64,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:12:59',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Gillard, Oliver J.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 90,
            'Name': 'CLR Volume 65',
            'Owner': null,
            'Number': 65,
            'Patron': 'KL Gates',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2014-10-10 23:04:14',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Gillard, Oliver J.; Jeffriess, B. J.; Wiseman, H. Dallas; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 91,
            'Name': 'CLR Volume 66',
            'Owner': null,
            'Number': 66,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Buller Murphy, B.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 92,
            'Name': 'CLR Volume 67',
            'Owner': null,
            'Number': 67,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 93,
            'Name': 'CLR Volume 68',
            'Owner': null,
            'Number': 68,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 94,
            'Name': 'CLR Volume 69',
            'Owner': null,
            'Number': 69,
            'Patron': 'Whittens Lawyers',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 95,
            'Name': 'CLR Volume 70',
            'Owner': null,
            'Number': 70,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:12:50',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 96,
            'Name': 'CLR Volume 71',
            'Owner': null,
            'Number': 71,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 97,
            'Name': 'CLR Volume 72',
            'Owner': null,
            'Number': 72,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:12:38',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 98,
            'Name': 'CLR Volume 73',
            'Owner': null,
            'Number': 73,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 99,
            'Name': 'CLR Volume 74',
            'Owner': null,
            'Number': 74,
            'Patron': 'King & Wood Mallesons',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:10:31',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 100,
            'Name': 'CLR Volume 75',
            'Owner': null,
            'Number': 75,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:12:26',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 101,
            'Name': 'CLR Volume 76',
            'Owner': null,
            'Number': 76,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:12:14',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 102,
            'Name': 'CLR Volume 77',
            'Owner': null,
            'Number': 77,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 103,
            'Name': 'CLR Volume 78',
            'Owner': null,
            'Number': 78,
            'Patron': 'Bennett and Co',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2014-02-14 07:48:57',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Burt, F. T. P.; Healy, E. F.; Jeffriess, B. J.; Wright, R. C.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 104,
            'Name': 'CLR Volume 79',
            'Owner': null,
            'Number': 79,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Burt, F. T. P.; Healy, E. F.; Jeffriess, B. J.; Rex, J. R.; Wright, R. C.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 105,
            'Name': 'CLR Volume 80',
            'Owner': null,
            'Number': 80,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Burt, F. T. P.; Healy, E. F.; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 106,
            'Name': 'CLR Volume 81',
            'Owner': null,
            'Number': 81,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Burt, F. T. P.; Healy, E. F.; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 107,
            'Name': 'CLR Volume 82',
            'Owner': null,
            'Number': 82,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Burt, F. T. P.; Healy, E. F.; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 108,
            'Name': 'CLR Volume 83',
            'Owner': null,
            'Number': 83,
            'Patron': 'Graeme Johnson',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Brebner, C. C.; Burt, F. T. P.; Healy, E. F.; Hunter, Brian; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 109,
            'Name': 'CLR Volume 84',
            'Owner': null,
            'Number': 84,
            'Patron': 'Martin Place Chambers',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:10:54',
            'Reporters': 'Bales, Joseph; Burt, F. T. P.; Healy, E. F.; Hunter, Brian; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 110,
            'Name': 'CLR Volume 85',
            'Owner': null,
            'Number': 85,
            'Patron': 'Bennett and Co',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2021-03-04 16:51:15',
            'Reporters': 'Bales, Joseph; Burt, F. T. P.; Healy, E. F.; Hunter, Brian; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 111,
            'Name': 'CLR Volume 86',
            'Owner': null,
            'Number': 86,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Healy, E. F.; Hunter, Brian; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 112,
            'Name': 'CLR Volume 87',
            'Owner': null,
            'Number': 87,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Hunter, Brian; Jeffriess, B. J.; Rex, J. R.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 113,
            'Name': 'CLR Volume 88',
            'Owner': null,
            'Number': 88,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Everett, M. G.; Hunter, Brian; Jeffriess, B. J.; Needham, G. D.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 114,
            'Name': 'CLR Volume 89',
            'Owner': null,
            'Number': 89,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Everett, M. G.; Hunter, Brian; Jeffriess, B. J.; Needham, G. D.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 115,
            'Name': 'CLR Volume 90',
            'Owner': null,
            'Number': 90,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Everett, M. G.; Howell, R. A.; Hunter, Brian; Jeffriess, B. J.; Needham, G. D.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 116,
            'Name': 'CLR Volume 91',
            'Owner': null,
            'Number': 91,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:11:44',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Cuthbert, W. J.; Everett, M. G.; Howell, R. A.; Hunter, Brian; Needham, G. D.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 117,
            'Name': 'CLR Volume 92',
            'Owner': null,
            'Number': 92,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:11:33',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Cuthbert, W. J.; Everett, M. G.; Howell, R. A.; Hunter, Brian; Needham, G. D.',
            'Requested': null,
            'Scanned': 1
        },
        {
            'ID': 118,
            'Name': 'CLR Volume 93',
            'Owner': null,
            'Number': 93,
            'Patron': 'Dauid Sibtain',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-08-23 11:03:35',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Everett, M. G.; Howell, R. A.; Hunter, Brian; Macrossan, J. M.; Needham, G. D.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 119,
            'Name': 'CLR Volume 94',
            'Owner': null,
            'Number': 94,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Everett, M. G.; Howell, R. A.; Hunter, Brian; Macrossan, J. M.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 120,
            'Name': 'CLR Volume 95',
            'Owner': null,
            'Number': 95,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Everett, M. G.; Howell, R. A.; Hunter, Brian; Macrossan, J. M.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 121,
            'Name': 'CLR Volume 96',
            'Owner': null,
            'Number': 96,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Howell, R. A.; Hunter, Brian; Lehane, T. J.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 122,
            'Name': 'CLR Volume 97',
            'Owner': null,
            'Number': 97,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Howell, R. A.; Hunter, Brian; Lehane, T. J.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 123,
            'Name': 'CLR Volume 98',
            'Owner': null,
            'Number': 98,
            'Patron': null,
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-06-16 16:49:03',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Howell, R. A.; Hunter, Brian; Lehane, T. J.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 124,
            'Name': 'CLR Volume 99',
            'Owner': null,
            'Number': 99,
            'Patron': 'Ninth Floor, Wentworth Chambers',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2012-09-06 15:11:14',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Hunter, Brian; Lehane, T. J.; Searby, Richard H.; Smith, G. H.',
            'Requested': null,
            'Scanned': 0
        },
        {
            'ID': 125,
            'Name': 'CLR Volume 100',
            'Owner': null,
            'Number': 100,
            'Patron': 'Claire Angyal',
            'ClassName': 'Book',
            'Created': '2010-06-16 16:49:03',
            'LastEdited': '2010-07-08 16:21:00',
            'Reporters': 'Bales, Joseph; Barton, Russell D.; Burt, F. T. P.; Hunter, Brian; Lehane, T. J.; Searby, Richard H.; Smith, G. H.',
            'Requested': null,
            'Scanned': 0
        }
    ]

    const [bookSelected, setBookSelected] = useState<Book>()
    const [books, setBooks] = useState<Book[]>()

    useEffect(() => {
        const sortedBooks = rawData.sort((a, b) => a.Number - b.Number)

        setBooks(sortedBooks)
        setBookSelected(sortedBooks[0])
    }, [])

    const handleUpdateBookInfo = (book: Book) => {
        setBookSelected(book)
    }

    const renderBookSelectedInfo = (book: Book) => (
        <section className={'sticky top-0 shadow'}>
            <div className="bg-gray-50 pt-12 sm:pt-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto text-center">
                        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mb-4">
                            {book.Name}
                        </h2>
                        <p className={'text-xl font-medium text-gray-500'}>
                            {book.Reporters}
                        </p>
                        <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                            {
                                book.Patron ? (`Sponsored by: ${book.Patron}`) : '-'
                            }
                        </p>
                        )

                    </div>
                </div>
            </div>
        </section>
    )

    const renderBook = (book: Book) => {
        return (
            <div onMouseOver={() => handleUpdateBookInfo(book)} onClick={() => handleUpdateBookInfo(book)}
                 className={'w-32 p-1 flex hover:bg-gray-200'}>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
                    <path
                        d="M36.75,33.31a4.46,4.46,0,0,1-4.55-4l0,.47v50.7a4.12,4.12,0,0,0,4.12,4.12H75.35a2,2,0,0,0,2-2V34.12a.82.82,0,0,0-.82-.81Z"
                        style={{ fill: book.Patron ? '#353A48' : '#FFF', stroke: '#000', strokeMiterlimit: 10 }} />
                    <path d="M37,84.43h-.71a4.12,4.12,0,0,1-4.12-4.1l0-51h0a4.45,4.45,0,0,0,4.55,3.95h.53v.13l-.29,51"
                          style={{ fill: '#E36E49', stroke: '#000', strokeMiterlimit: 10 }} />
                    <path
                        d="M75.4,33.31H36.55a4.41,4.41,0,0,1-4.39-4.39h0a4.41,4.41,0,0,1,4.39-4.39H74.83a.58.58,0,0,1,.57.58v8.2Z"
                        style={{ fill: '#E8ECE8', stroke: '#000', strokeMiterlimit: 10 }}
                    />
                    <rect x="48.69" y="49.42" width="17.22" height="8.8" rx="1.09"
                          style={{ fill: '#E36E49', stroke: '#000', strokeMiterlimit: 10 }} />
                </svg>
            </div>
        )
    }

    return (
        <section className={'bg-gray-50 pb-6'}>
            <div className={`max-w-7xl mx-auto mb-8`}>
                <img className={`max-w-xl mx-auto py-12`} src={OneTo100Logo} />
                <h1 className={'sr-only text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'}>
                    One to 100
                </h1>
                <p className={`text-2xl text-gray-500 text-center`}>Crowd sourcing to enable open access<br /> to the
                    first 100 Commonwealth Law Reports on JADE</p>
            </div>
            <section>
                <section className={'sticky top-0'}>
                    {bookSelected && renderBookSelectedInfo(bookSelected)}
                </section>
                <div className={`flex flex-wrap justify-center max-w-7xl mx-auto pt-4`}>
                    {books && books.map((book) => renderBook(book))}
                </div>
            </section>
        </section>
    )


}

export default ClrsInfograph
