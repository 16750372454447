import React, {FunctionComponent} from "react"
import { Widget } from '@typeform/embed-react'


class EnterpriseEnquiryFormProps {
}

const EnterpriseEnquiryForm: FunctionComponent<EnterpriseEnquiryFormProps> = (props) => {
    return <Widget id="V1wPyjPn" style={{height:820}} className="my-form" />
}

export default  EnterpriseEnquiryForm
