import { ProjectColours } from '@open-law/open-law-shared'

export const setProjectColourStyles = (colour: ProjectColours, background?: boolean, includeHover?: boolean) => {
    switch (colour) {
        case 'blue':
            return `${background ? 'bg-blue-500 text-white' : 'text-blue-500'} ${includeHover ? 'hover:text-white hover:bg-blue-700' : ''}`
        case 'red':
            return `${background ? 'bg-red-500 text-white' : 'text-red-500'} ${includeHover ? 'hover:text-white hover:bg-red-700' : ''}`
        case 'green':
            return `${background ? 'bg-green-500 text-white' : 'text-green-500'} ${includeHover ? 'hover:text-white hover:bg-green-700' : ''}`
        case 'purple':
            return `${background ? 'bg-purple-600 text-white' : 'text-purple-500'} ${includeHover ? 'hover:text-white hover:bg-purple-700' : ''}`
        case 'black':
            return `${background ? 'bg-black text-white' : 'text-grey-500'} ${includeHover ? 'hover:text-white hover:bg-gray-700' : ''}`
        case 'gray':
            return `${background ? 'bg-gray-800 text-white' : 'text-grey-500'} ${includeHover ? 'hover:text-white hover:bg-gray-700' : ''}`
        case 'dark-green':
            return `${background ? 'bg-yellow-500 text-black' : 'text-heading-green'} ${includeHover ? 'hover:text-white hover:bg-yellow-700' : ''}`
        case 'pink':
            return `${background ? 'bg-pink-500 text-black' : 'text-pink-500'} ${includeHover ? 'hover:text-white hover:bg-pink-700' : ''}`
        default:
            return `${background ? 'bg-black text-white' : 'text-grey-500'} ${includeHover ? 'hover:text-white hover:bg-blue-700' : ''}`
    }
}

export const setProjectColourDarkButtonStyles = (colour: ProjectColours) => {
    switch (colour) {
        case 'blue':
            return 'bg-blue-400 hover:bg-blue-800 text-white'
        case 'green':
            return 'bg-green-400 hover:bg-green-800 text-white'
        case 'gray':
            return 'bg-gray-400 hover:bg-gray-800 text-white'
        case 'dark-green':
            return 'bg-heading-green hover:bg-heading-green text-white'
        case 'red':
            return 'bg-red-400 hover:bg-red-800 text-white'
        case 'purple':
            return 'bg-purple-400 hover:bg-purple-800 text-white'
        case 'indigo':
            return 'bg-indigo-400 hover:bg-indigo-800 text-white'
        case 'pink':
            return 'bg-pink-400 hover:bg-pink-800 text-white'
        case 'black':
            return 'bg-black hover:bg-gray-600 text-white'

    }
}

export const setProjectColourLightButtonStyles = (colour: ProjectColours) => {
    switch (colour) {
        case 'blue':
            return 'bg-blue-800 hover:bg-blue-400 text-white'
            break
        case 'green':
            return 'bg-green-800 hover:bg-green-400 text-white'
        case 'gray':
            return 'bg-gray-800 hover:bg-gray-400 text-white'
        case 'dark-green':
            return 'bg-heading-green hover:bg-yellow-400 text-white'
        case 'red':
            return 'bg-red-800 hover:bg-red-400 text-white'
        case 'purple':
            return 'bg-purple-800 hover:bg-purple-400 text-white'
        case 'indigo':
            return 'bg-indigo-800 hover:bg-indigo-400 text-white'
        case 'pink':
            return 'bg-pink-800 hover:bg-pink-400 text-white'
        case 'black':
            return 'bg-black hover:bg-gray-600 text-white'

    }
}

export const getBackgroundColour = (colour: ProjectColours, dark: boolean) => {
    switch (colour) {
        case 'blue': {
            return dark ? 'bg-blue-900' : 'bg-blue-50'
        }
        case 'green': {
            return dark ? 'bg-green-900' : 'bg-green-50'
        }
        case 'gray': {
            return dark ? 'bg-gray-900' : 'bg-gray-50'
        }
        case 'dark-green': {
            return dark ? 'bg-heading-green' : 'bg-heading-green'
        }
        case 'red': {
            return dark ? 'bg-red-900' : 'bg-red-50'
        }
        case 'purple': {
            return dark ? 'bg-purple-900' : 'bg-purple-50'
        }
        case 'indigo': {
            return dark ? 'bg-indigo-900' : 'bg-indigo-50'
        }
        case 'pink': {
            return dark ? 'bg-pink-900' : 'bg-pink-50'
        }
        case 'black': {
            return dark ? 'bg-black' : 'bg-gray-50'
        }
        default: {
            return dark ? 'bg-blue-900' : 'bg-blue-50'
        }
    }
}

export const getBorderStyle = (colour: ProjectColours, dark: boolean) => {
    switch (colour) {
        case 'blue': {
            return dark ? 'border-blue-900' : 'border-blue-50'
        }
        case 'green': {
            return dark ? 'border-green-900' : 'border-green-50'
        }
        case 'gray': {
            return dark ? 'border-gray-900' : 'border-gray-50'
        }
        case 'dark-green': {
            return dark ? 'border-heading-green' : 'border-yellow-50'
        }
        case 'red': {
            return dark ? 'border-red-900' : 'border-red-50'
        }
        case 'purple': {
            return dark ? 'border-purple-900' : 'border-purple-50'
        }
        case 'indigo': {
            return dark ? 'border-indigo-900' : 'border-indigo-50'
        }
        case 'pink': {
            return dark ? 'border-pink-900' : 'border-pink-50'
        }
        case 'black': {
            return dark ? 'border-black' : 'border-gray-50'
        }
        default: {
            return dark ? 'border-blue-900' : 'border-blue-50'
        }
    }
}
