import React, {FunctionComponent} from 'react'
import {ProjectColours, Webinar, WebinarPart} from '@open-law/open-law-shared'

interface WebinarVideoListComponentData {
    title: string
    webinar: Webinar
    projectColour: ProjectColours
}


interface WebinarVideoListProps {
    data: WebinarVideoListComponentData
}

const WebinarVideoList: FunctionComponent<WebinarVideoListProps> = (props) => {


    const {title, webinar, projectColour} = props.data


    const renderVideoListItem = (e: WebinarPart) => (
        <dl className="mb-5 space-y-10 px-24">
            <div className="bg-gray-100 pt-5 pb-5 px-4 sm:px-6 lg:pt-5 lg:pb-5 lg:px-8 rounded-lg h-28">
                <a href={`/webinars/${webinar.slug.current}/${e && e.slug && e.slug.current ? e.slug.current : ''}`}
                   className="block">
                    <div className="flex ">
                        <div className="flex-shrink-0">
                            <div className="flex items-center h-12 w-12 rounded-md text-black">
                                {
                                    e.icon ? <img src={e.icon.asset.url}/> :
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M9 5l7 7-7 7"/>
                                        </svg>
                                }
                            </div>
                        </div>
                        <div className="ml-4">
                            {
                                e.title && (
                                    <dt className="text-lg leading-6 font-medium text-gray-900">
                                        {e.title}
                                    </dt>
                                )
                            }
                            <dd className="mt-0.5 text-base text-gray-500">
                                {/*<PortableTextRenderer blocks={e.partBlurb} projectColour={projectColour}/>*/}
                                {e.partBlurb}
                            </dd>
                        </div>
                    </div>
                </a>
            </div>
        </dl>
    )

    return (
        <div className="relative flex justify-center bg-white pt-5 pb-40 px-4 sm:px-6 lg:pt-5 lg:pb-40 lg:px-8">
            <section className="w-8/12">
                {/*<h1 className="text-5xl font-semibold text-center text-gray-900 py-8">*/}
                {/*    {title}*/}
                {/*</h1>*/}
                {
                    webinar.parts.map((p) => renderVideoListItem(p))
                }
            </section>
        </div>
    )
}

export default WebinarVideoList
