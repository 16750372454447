import React, { FunctionComponent } from 'react'
import { TestimonialComponentData } from '@open-law/open-law-shared'


interface TestimonialProps {
    testimonialComponentData: TestimonialComponentData
}

const Testimonials: FunctionComponent<TestimonialProps> = (props) => {

    const { testimonialComponentData } = props
    const { testimonials, backgroundColour } = testimonialComponentData

    return (
        <section className="bg-blue-800">
            <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
                {
                    testimonials && testimonials.map((t) => (
                            <div key={t.name}
                                className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:pr-16">
                                <div className="md:flex-shrink-0">
                                    {/* COMPANY LOGO === */}
{/*                                    {
                                        t.logo && t.logo.asset && t.logo.asset.url && (
                                            <img className="h-12" alt={t.logo.alt} src={t.logo.asset.url} />
                                        )
                                       }
                                    */}
                                </div>
                                <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                                    <div className="relative text-lg font-medium text-white md:flex-grow">
                                        <svg
                                            style={{color: '#3D84C3'}}
                                            className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8"
                                            fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                                            <path
                                                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                        </svg>
                                        {/* TESTIMONIAL TEXT === */}
                                        {
                                            t.text.split(`\n`).map((text, idx) => (
                                                <p key={idx} className="relative mb-2">
                                                    {
                                                        text
                                                    }
                                                </p>
                                            ))
                                        }
                                    </div>
                                    {/* PERSON GIVING THE TESTIMONIAL */}
                                    <footer className="mt-8">
                                        <div className="flex">
{/*                                            <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                                                {
                                                    t.portrait && (
                                                        <img className="h-12 w-12 rounded-full"
                                                             src={t.portrait.asset.url}
                                                             alt={t.portrait.alt} />
                                                    )
                                                }
                                            </div>*/}
                                            <div className="">
                                                <div className="text-base font-medium text-white">
                                                    {t.name}
                                                </div>
                                                <div className="text-base font-medium text-blue-200">
                                                    {t.position}{t.organisation && `, ${t.organisation}`}
                                                </div>
                                            </div>
                                        </div>
                                    </footer>
                                </blockquote>
                            </div>
                        )
                    )
                }
            </div>
        </section>
    )
}

export default Testimonials
