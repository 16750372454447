import {
    Deal as IDeal,
    DealGroup,
    DealScheme,
    PortableTextRenderer,
    PricingScheme,
    PricingSchemeTypes
}                               from '@open-law/open-law-shared'
import React, {
    FunctionComponent,
    useEffect,
    useState
}                               from 'react'
import BillingFrequencySelector from './BillingFrequencySelector'
import Deal                     from './Deal'
import SinglePriceWithFeatures  from './SinglePriceWithFeatures'
import ThreeDealsFeaturedDeal   from './ThreeDealsFeaturedDeal'


interface PricingTableProps {
    scheme: PricingScheme | DealScheme
    type: PricingSchemeTypes
}

const PricingTable: FunctionComponent<PricingTableProps> = (props) => {
    // console.groupCollapsed('Pricing Table Props')
    // console.log('Props: ', props)
    // console.log('Pricing Scheme Type: ', props.type)
    // console.log('Pricing Scheme Data:', props.scheme)
    // console.groupEnd()


    const {schemeTitle, schemeCatchphrase, belowSplitterTextComponent} = props.scheme

    const [selectedBillingFrequency, setBillingFrequency] = useState<string>('yearly')
    const [dealGroupSelected, setDealGroupSelected]       = useState<string>()


    useEffect(() => {
        if (props.type === 'dealScheme') {
            const dealScheme = props.scheme as DealScheme
            setDealGroupSelected(dealScheme.dealGroups[0].dealGroupName)
        }
    }, [])

    const handleChangeBillingFrequency = (billingPeriod: string) => setBillingFrequency(billingPeriod)

    const handleChangeDealGroupSelected = (dealGroupName: string) => setDealGroupSelected(dealGroupName)

    const handleDealColumns = (numberOfDeals: number) => {
        return numberOfDeals <= 2
               ? 'xl:grid-cols-2'
               : numberOfDeals === 3
                 ? 'xl:grid-cols-3'
                 : numberOfDeals >= 4 && 'xl:grid-cols-4'

    }

    const renderDealGroupSplitter = (scheme: DealScheme) => (
        <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            {
                scheme?.dealGroups ? scheme.dealGroups.map((dealGroup) => {
                        return (
                            <button key={dealGroup.dealGroupName} type="button"
                                    onClick={() => handleChangeDealGroupSelected(dealGroup.dealGroupName)}
                                    className={`relative w-1/2 ${dealGroupSelected === dealGroup.dealGroupName ? 'bg-white' : ''} border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8 capitalize`}>
                                {dealGroup.dealGroupName}
                            </button>
                        )
                    }
                ) : null
            }
        </div>
    )

    const renderSplitter = (type: PricingSchemeTypes, scheme: PricingScheme | DealScheme) => {
        switch (type) {
            case 'dealScheme': {
                const dealScheme = scheme as DealScheme
                return renderDealGroupSplitter(dealScheme)
            }
            case 'pricingScheme': {
                const pricingScheme = scheme as PricingScheme
                return <BillingFrequencySelector deals={pricingScheme.deals}
                                                 selectedBillingFrequency={selectedBillingFrequency}
                                                 handleChangeBillingFrequency={handleChangeBillingFrequency}/>
            }
            case 'dealGroup' : {
                return
            }
            case 'singleDeal': {
                return
            }

        }
    }

    const renderDeals = (type: PricingSchemeTypes, scheme: PricingScheme | DealScheme | DealGroup | IDeal) => {
        switch (type) {
            case 'dealGroup': {
                const dealGroup = scheme as DealGroup
                return <ThreeDealsFeaturedDeal dealGroup={dealGroup}/>
            }
            case 'singleDeal' : {
                const deal = scheme as IDeal
                return <SinglePriceWithFeatures deal={deal}/>
            }
            case 'pricingScheme': {
                const pricingScheme   = props.scheme as PricingScheme
                const {deals}         = pricingScheme
                const numberOfColumns = handleDealColumns(deals.length)
                return pricingScheme.deals && (
                    <div
                        className={`justify-center mt-8 space-y-4 sm:mt-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 ${numberOfColumns}`}>
                        {
                            deals.map((deal) => <Deal key={deal.dealName} deal={deal}
                                                      selectedBillingFrequency={selectedBillingFrequency}/>)
                        }
                    </div>
                )
            }
            case 'dealScheme': {
                if (props.scheme?.dealGroups) {
                    const dealScheme   = props.scheme as DealScheme
                    const {dealGroups} = dealScheme

                    const selectedDealGroup: DealGroup | undefined = dealGroups.find((dealGroup) => {
                        return dealGroup.dealGroupName === dealGroupSelected
                    })
                    return <>
                        <div
                            className={`justify-center mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 ${handleDealColumns(selectedDealGroup ? selectedDealGroup.deals.length : 3)}`}>
                            {
                                selectedDealGroup &&
                                dealGroupSelected === selectedDealGroup.dealGroupName &&
                                selectedDealGroup.deals.map(
                                    (deal) => (
                                        <Deal key={deal.dealName} deal={deal}
                                              selectedBillingFrequency={selectedBillingFrequency}/>
                                    )
                                )
                            }
                        </div>
                        <p className={`mt-3 text-black font-medium text-center`}>
                            {selectedDealGroup && selectedDealGroup.dealGroupDescription}
                        </p>
                    </>
                }
            }
        }
    }

    return (
        <section>
            <div className="bg-gray-100">
                <div className="max-w-4xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:flex-col sm:align-center">
                        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
                            {schemeTitle}
                        </h1>
                        <p className="mt-5 text-xl text-gray-500 sm:text-center">
                            {schemeCatchphrase}
                        </p>
                        {renderSplitter(props.type, props.scheme)}
                        <div className="my-8 self-center">
                            <PortableTextRenderer projectColour={'blue'} blocks={belowSplitterTextComponent}/>
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24">
                    {
                        renderDeals(props.type, props.scheme)
                    }
                </div>
            </div>
        </section>
    )
}

export default PricingTable
